import React from "react";

import { Drawer, List, makeStyles } from "@material-ui/core";
import Menu, { ListHeader, ListUser } from "./lists";

import AuthenticationService from "../../../services/AuthenticationService";
import Footer from "../../Footer";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

function logoutHandler(history) {
  AuthenticationService.logout();

  history.push("/login");
}

export default function Appdrawer({ drawerOpen, toggleDrawer, history }) {
  const classes = useStyles();

  let user = "";
  try {
    const userData = AuthenticationService.getUserData();
    user = userData['full_name']
  } catch (err) {}

  return (
    <Drawer open={drawerOpen} onClose={toggleDrawer}>
      <div className={classes.root}>
        <List component="nav" style={{ minWidth: "250px" }}>
          <ListHeader />
        </List>

        <List component="nav" style={{ minWidth: "250px" }}>
          <ListUser
            userName={user}
            toggleDrawer={toggleDrawer}
            logoutHandler={() => logoutHandler(history)}
          />{" "}
        </List>

        <List component="nav" style={{ minWidth: "250px" }}>
          <Menu onClick={toggleDrawer} />
        </List>
      </div>
      <Footer />
    </Drawer>
  );
}
