import { useState, useEffect } from 'react';

// UI
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup'


const defaultOnCategoryChange = () => {};

const CategorySelector = (
    { categories, initCategory, onCategoryChange = defaultOnCategoryChange, ...others}) => {
    const [activeCategory, setActiveCategory] = useState(initCategory);


    const handleButtonClick = (event) => {
        const buttonId = event.currentTarget.id;
        setActiveCategory(buttonId);
    };

    useEffect(() => {
        onCategoryChange(activeCategory);
    }, [activeCategory]);

    return (
        <ButtonGroup {...others}>
            {categories.map((category, index) =>
                <Button
                    id={category.field}
                    variant={activeCategory === category.field ? "contained" : "outlined"}
                    key={index}
                    style={{textTransform: 'none'}}
                    onClick={handleButtonClick}
                >{category.title}</Button>
            )}
        </ButtonGroup>
    );
};

export default CategorySelector;