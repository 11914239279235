import React, { useEffect, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4lang_it_IT from "@amcharts/amcharts4/lang/it_IT";

import _ from "lodash";

am4core.useTheme(am4themes_animated);


function MultiAxisLineChart({id, data, charTitle, fields, mapper}) {
  const [chartRef, setChartRef] = useState(null);

  useEffect(() => {
    let chart = am4core.create(id, am4charts.XYChart);

    chart.data = data;
    chart.logo.disabled = true;
    chart.tapToActivate = false;

    chart.numberFormatter.numberFormat = "#,###.##";
    chart.numberFormatter.language.locale = am4lang_it_IT;

    let title = chart.titles.create();
    title.text = charTitle;
    title.fontSize = 20;
    title.marginBottom = 20;

    // Create axes
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 50;

    // Create series and axis
    function createAxisAndSeries(field, name, dashed=false){
      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      if(chart.yAxes.indexOf(valueAxis) !== 0){
        valueAxis.syncWithAxis = chart.yAxes.getIndex(0);
        valueAxis.renderer.opposite = true;
        valueAxis.title.text = "Energia [[kWh]]";
      } else {
        valueAxis.title.text = "Volume [[Nmc]]";
      }

      let series = chart.series.push(new am4charts.LineSeries());
      series.name = name;
      series.dataFields.valueY = field;
      series.dataFields.dateX = "date";
      series.strokeWidth = 2;
      series.yAxis = valueAxis;
      series.minBulletDistance = 10;
      series.tooltipText = "[bold]{date.formatDate()}:[/] {valueY}";
      series.tooltip.pointerOrientation = "vertical";

      if (dashed) {
        series.strokeDasharray = "3,4";
        series.stroke = series.stroke;
      }
    }

    for (let f of fields) {
      createAxisAndSeries(f, mapper[f]);
    }

    // Add chart cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "zoomX";

    // Legend
    chart.legend = new am4charts.Legend();

    setChartRef(chart);

    return () => {
      chart.dispose();
    };
  }, [data, id]);

  if (chartRef) {
    console.log("Created chart with id:", chartRef.baseId);
  }

  return (
    <div id={id} style={{ minWidth: "500px", minHeight: "400px" }} />
  );
}

export default React.memo(MultiAxisLineChart, (props, nextProps) => {
  // Render component only when users changes
  return _.isEqual(props.data, nextProps.data);
});
