import React, {useState} from 'react';

// Services
import ApiService from "../../services/ApiService";

// UI
import {CircularProgress, Dialog, DialogContent, DialogTitle, InputLabel, Select, TextField} from '@material-ui/core';
import {Button, Divider, Grid,  MenuItem} from '@material-ui/core';
import makeStyles from "@material-ui/core/styles/makeStyles";

// Utils
import {ROLES} from "../../utils/constants";
import { useFormik } from "formik";
import * as Yup from "yup";
import _ from "lodash";


const useStyle = makeStyles(theme => ({
    button: {
      margin: theme.spacing(3, 0, 2),
    }
}))

const USER_INIT_STATE = {
  "email": "",
  "role": "",
  "scopes": "",
  "full_name": "",
  "password": "",
  "disabled": true,
};

const REQUIRED = "Campo richiesto!";
const EMAIL = "Il campo deve essere una email!";

function AddUserDialog({open, toggleOpen, openAlert, users, setUsers}) {

  const [loading, setLoading] = useState(false);
  const classes = useStyle();
  const schema = Yup.object({
    full_name: Yup.string().required(REQUIRED),
    role: Yup.string().required(REQUIRED),
    email: Yup.string().email(EMAIL).required(REQUIRED),
    scopes: Yup.string().required(REQUIRED),
    disabled: Yup.bool().required(REQUIRED)
  });

  const _onSubmit = (user, {resetForm}) => {
    console.log("Submit");
    setLoading(true);
    console.log(user);
    ApiService.postApiJson("users", user)
      .then((data) => {
        console.log(data);
        setUsers({...users, [data.email]: data});
        resetForm({});
        toggleOpen();
        openAlert("success", "Utente creato con successo!");
      })
      .catch((err) => {
        console.log(err)
        openAlert("error", "Username e/o E-mail già in uso!");
      })
      .finally(() => setLoading(false))
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    values,
    errors,
  } = useFormik({
    initialValues: USER_INIT_STATE,
    validationSchema: schema,
    onSubmit: _onSubmit,
  });

  const isFieldValidationError = (name) =>
    touched[name] && errors[name];

  const getFieldError = (name) =>
    isFieldValidationError(name)
      ? errors[name]
      : "";

  return (
    <div>
      <Dialog open={open} onClose={toggleOpen}>
        <DialogTitle>Aggiungi utenza</DialogTitle>
        <DialogContent>
          <form id="add-user-form" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  id="full_name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={_.get(values, "full_name")}
                  margin="dense"
                  label="Nome e cognome"
                  name="full_name"
                  type="text"
                  variant="outlined"
                  fullWidth
                  required
                  error={isFieldValidationError("full_name")}
                  helperText={getFieldError("full_name")}
                />
              </Grid>
              <Divider />
              <Grid item xs={12}>
                <TextField
                  id="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={_.get(values, "email")}
                  margin="dense"
                  label="Email"
                  name="email"
                  type="text"
                  variant="outlined"
                  fullWidth
                  required
                  error={isFieldValidationError("email")}
                  helperText={getFieldError("email")}
                />
              </Grid>

              <Divider variant="middle" />

              <Grid item xs={12}>
                <InputLabel id="user-role">Ruolo</InputLabel>
                <Select
                  labelId="user-role"
                  id="demo-simple-select"
                  name="role"
                  value={_.get(values, "role")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  required
                  error={isFieldValidationError("role")}
                  helperText={getFieldError("role")}
                >
                  {ROLES.map((userRole) =>
                    <MenuItem key={userRole.value} value={userRole.value}>{userRole.label}</MenuItem>
                  )}
                </Select>
              </Grid>

              <Grid item xs={12}>
                <InputLabel id="user-scopes">Scopes</InputLabel>
                <Select
                  labelId="user-scopes"
                  id="demo-simple-select"
                  name="scopes"
                  value={_.get(values, "scopes")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  required
                  error={isFieldValidationError("scopes")}
                  helperText={getFieldError("scopes")}
                >
                  {ROLES.map((userRole) =>
                    <MenuItem key={userRole.value} value={userRole.value}>{userRole.label}</MenuItem>
                  )}
                </Select>
              </Grid>

              <Grid item xs={12}>
                <InputLabel id="user-disabled">Disabled</InputLabel>
                <Select
                  labelId="user-disabled"
                  id="demo-simple-select"
                  name="disabled"
                  value={_.get(values, "disabled")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  required
                  error={isFieldValidationError("disabled")}
                  helperText={getFieldError("disabled")}
                >
                  <MenuItem value={true}>true</MenuItem>
                  <MenuItem value={false}>false</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </form>
          <Grid container>
            <Grid item xs={5}>
              <Button onClick={toggleOpen} variant="contained" className={classes.button}>
                Esci
              </Button>
            </Grid>
            <Grid item xs={2}>
              <div style={{textAlign: "center"}}>
                {loading && <CircularProgress size={30} style={{marginTop: 25}}/>}
              </div>
            </Grid>
            <Grid item xs={5} style={{textAlign: "end"}}>
              <Button
                form="add-user-form"
                type="submit"
                color="primary"
                variant="contained"
                className={classes.button}
                disabled={!_.get(values, "role")}
              >
                Aggiungi
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AddUserDialog;