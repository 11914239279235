import React from "react";

// UI
import MaterialTable from 'material-table';
import _ from "lodash";

// Table Params
import {LOCALIZATION} from "./Params/Basic"
import {tableIcons} from "./Params/Icons";

export const OPTIONS = {
  filtering: true,
  showTitle: false,
  toolbar: false,
  paging: true,
  sorting: true,
  headerStyle: {fontWeight: "bold", fontSize: 15, paddingTop: 5, paddingBottom: 5},
  filterCellStyle: {paddingTop: 8, maxWidth: 200},
  cellStyle: {paddingBottom: 5},
  rowStyle: x => {
    if (x.class === "Totale") {return {fontWeight: "bold"}}
  }
};

function GenericTable({data, columns}) {
  return (
    <>
      <MaterialTable
        columns={columns}
        data={data}
        icons={tableIcons}
        isLoading={data === undefined}
        options={OPTIONS}
        localization={LOCALIZATION}
      />
    </>
  );
}

export default React.memo(GenericTable, (props, nextProps) => {
  // Render component only when users changes
  return _.isEqual(props.data, nextProps.data);
})
