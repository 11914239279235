import React  from 'react';

// UI
import Grid from '@material-ui/core/Grid';
import {
    KeyboardDateTimePicker,
    DatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';

// Date Utilities
import DateFnsUtils from '@date-io/date-fns';
import format from "date-fns/format";
import itLocale from "date-fns/locale/it";

class LocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return format(date, "dd/MM/yyyy", { locale: this.locale });
  }
}


const DateTimePicker = ({ selectedDate, setSelectedDate, minDate, maxDate, label }) => {

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <>
      <MuiPickersUtilsProvider utils={LocalizedUtils} locale={itLocale}>
        <Grid container justify="space-around">
          <DatePicker
            variant="dialog"
            format="dd/MM/yyyy"
            ampm={false}
            label={label}
            cancelLabel="Annulla"
            value={selectedDate}
            minDate={minDate}
            maxDate={maxDate}
            onChange={handleDateChange}
          />
        </Grid>
      </MuiPickersUtilsProvider>
    </>
  );

};

export default DateTimePicker;