import React from "react";

export const COLUMNS = [
  {
    title: "Giorni",
    field: "day",
    type: "string",
  },
  {
    title: "Ore",
    field: "hour",
    type: "string",
  },
  {
    title: "Somma di Compressori",
    field: "acSum",
    type: "numeric",
    // render: rowData => <>{Number(rowData['value']).toLocaleString('it-IT')}</>
  },
  {
    title: "Sommma di Produzione",
    field: "prodSum",
    type: "numeric",
    // render: rowData => <>{Number(rowData['value']).toLocaleString('it-IT')}</>
  }
]

export const OPTIONS = {
  filtering: true,
  showTitle: true,
  toolbar: true,
  paging: true,
  sorting: true,
  headerStyle: {fontWeight: "bold", fontSize: 15, paddingTop: 5, paddingBottom: 5},
  filterCellStyle: {paddingTop: 8, maxWidth: 200},
  cellStyle: {paddingBottom: 5}
};

export const LOCALIZATION = {
  header: {
    actions: ""
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} di {count} righe",
    labelRowsSelect: "Righe",
  }
};
