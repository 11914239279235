import React from "react";

// UI
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

// Utils
import _ from "lodash";


function SimpleAlert({ alert, toggleAlert}) {
  const { message, open, type } = alert;

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={toggleAlert}>
        <Alert onClose={toggleAlert} severity={type}>
          {message}
        </Alert>
    </Snackbar>
  );
}

function Alert(props) {
  return <MuiAlert elevation={6} {...props}/>;
}

function shouldUpdate(prevProps, props) {
  return _.isEqual(prevProps.alert, props.alert);
}

export default React.memo(SimpleAlert, shouldUpdate);