import _ from "lodash";
import React, { useEffect, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4lang_it_IT from "@amcharts/amcharts4/lang/it_IT";

am4core.useTheme(am4themes_animated);


function BarChart({id, data, charTitle, fields, mapper}) {
  const [chartRef, setChartRef] = useState(null);

  useEffect(() => {
    let chart = am4core.create(id, am4charts.XYChart);

    chart.data = data;
    chart.logo.disabled = true;
    chart.tapToActivate = false;

    chart.numberFormatter.numberFormat = "#,###.##";
    chart.numberFormatter.language.locale = am4lang_it_IT;

    // define chart granularity
    chart.dateFormatter.inputDateFormat = "yyyy-MM-dd HH:mm:ss";

    let title = chart.titles.create();
    title.text = charTitle;
    title.fontSize = 20;
    title.marginBottom = 20;

    // Create axes
    // TODO: setup date into italian format!
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 50;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "[[kWh/Nmc]]";

    // Create series
    function createSeries(field, name){
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.name = name;
      series.dataFields.valueY = field;
      series.dataFields.dateX = "date";
      series.strokeWidth = 2;
      series.minBulletDistance = 10;
      series.tooltipText = "[bold]{date.formatDate()}:[/] {valueY}";
      series.columns.template.fillOpacity = .8;

      let columnTemplate = series.columns.template;
      columnTemplate.strokeWidth = 2;
      columnTemplate.strokeOpacity = 1;
    }

    for (let f of fields) {
      createSeries(f, mapper[f]);
    }

    // Add label
    // let labelBullet = series.bullets.push(new am4charts.LabelBullet());
    // labelBullet.label.text = "{valueY}";
    // labelBullet.locationY = 0.9;
    // labelBullet.label.hideOversized = true;

    // Add chart cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "zoomX";

    // Legend
    // chart.legend = new am4charts.Legend();

    setChartRef(chart);

    return () => {
      chart.dispose();
    };
  }, [data, id]);

  if (chartRef) {
    console.log("Created chart with id:", chartRef.baseId);
  }

  return (
    <div id={id} style={{ minWidth: "500px", minHeight: "400px" }} />
  );
}

export default React.memo(BarChart, (props, nextProps) => {
  // Render component only when users changes
  return _.isEqual(props.data, nextProps.data);
});
