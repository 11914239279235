import React, { useEffect, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4lang_it_IT from "@amcharts/amcharts4/lang/it_IT";

import _ from "lodash";

am4core.useTheme(am4themes_animated);


function LineChart({id, data, charTitle, fields, mapper, percent=false}) {
  const [chartRef, setChartRef] = useState(null);

  useEffect(() => {
    let chart = am4core.create(id, am4charts.XYChart);

    chart.data = data;
    chart.logo.disabled = true;
    chart.tapToActivate = false;

    chart.numberFormatter.numberFormat = "#,###.##";
    chart.numberFormatter.language.locale = am4lang_it_IT;

    let title = chart.titles.create();
    title.text = charTitle;
    title.fontSize = 20;
    title.marginBottom = 20;

    // Create axes
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 50;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    if (percent) {
      valueAxis.min = 0;
      //valueAxis.max = 2;
      valueAxis.strictMinMax = true;
      valueAxis.renderer.labels.template.adapter.add("text", (text) => {
        return `${text}%`;
      });
    }

    // Create series
    function createSeries(field, name, dashed=false){
      let series = chart.series.push(new am4charts.LineSeries());
      series.name = name;
      series.dataFields.valueY = field;
      series.dataFields.dateX = "date";
      series.strokeWidth = 2;
      series.minBulletDistance = 10;

      if (percent) {
        series.fillOpacity = 0.3;
        series.tooltipText = "[bold]{date.formatDate()}:[/] {valueY}%";
      } else {
        series.tooltipText = "[bold]{date.formatDate()}:[/] {valueY}";
      }

      series.tooltip.pointerOrientation = "vertical";

      if (dashed) {
        series.strokeDasharray = "3,4";
        series.stroke = series.stroke;
      }
    }

    for (let f of fields) {
      createSeries(f, mapper[f]);
    }

    // Add chart cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "zoomX";

    // Legend
    chart.legend = new am4charts.Legend();

    setChartRef(chart);

    return () => {
      chart.dispose();
    };
  }, [data, id]);

  if (chartRef) {
    console.log("Created chart with id:", chartRef.baseId);
  }

  return (
    <div id={id} style={{ minWidth: "500px", minHeight: "400px" }} />
  );
}

export default React.memo(LineChart, (props, nextProps) => {
  // Render component only when users changes
  return _.isEqual(props.data, nextProps.data);
});
