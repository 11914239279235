import moment from 'moment';
import React, {useState, useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";

import { productionGranularities, prodKpiMappings } from "../../utils/constants";

// Services
import AuthenticationService from "../../services/AuthenticationService";
import ApiService from "../../services/ApiService";

// UI
import { LinearProgress } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from "@material-ui/core/Typography";
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';

// Components
import LineChart from "../../components/Charts/LineChart";
import DateTimePicker from "../../components/DateTimePicker";
import GenericTable from "../../components/Tables/GenericTable";

// Style
const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1
  },
  gridItem: {
    marginLeft: "auto",
    marginRight: "auto"
  }
}));

const MULTI_AUTOCOMPLETE_LIMIT = 7;

const table_data = [
  { efficienza_media: "98,9%" },
]

const KPI_TEMPI_COLUMNS = [
  { title: 'Cliente', field: 'cliente' },
  { title: 'Articolo', field: 'articolo' },
  { title: 'Lavorazione', field: 'lavorazione' },
  { title: 'Scarti %', field: 'scarti_perc' },
]

export default function ScartiProduzione() {
  const userData = AuthenticationService.getUserData();

  const [menu, setMenu] = useState({cliente:[], articolo:[], lavorazioni:[]});
  const [lavorazioni, setLavorazioni] = useState([]);

  const [clienteSelected, setClienteSelected] = useState({GruppoCliente:'Montorso'});
  const [articoloSelected, setArticoloSelected] = useState('');
  const [lavorazioniSelected, setLavorazioniSelected] = useState([]);

  const [dataPlot, setDataPlot] = React.useState();
  const [dataTable, setDataTable] = React.useState();

  const [granularity, setGranularity] = useState(productionGranularities.DAILY);
  const [startDate, setStartDate] = useState(moment(new Date()).subtract({month: 1}).toDate());
  const [endDate, setEndDate] = useState(moment());
  const [loading, setLoading] = useState(false);

  const [alert, setAlert] = useState({open: false, type: "", message: ""});
  const toggleAlert = () => {setAlert({...alert, open: !alert.open})}
  const openAlert = (type, message) => {setAlert({open: true, type, message})}

  const handleGranularityChange = (value) => {
    setGranularity(value.target.value);
  };

  const fetchData = async (startDate, endDate, granularity, cliente, articolo, lavorazioni) => {
    console.log("fetching data...");
    setLoading(true)

    let start_date = startDate.toISOString().split('T')[0];
    let end_date = moment(endDate, "DD-MM-YYYY").add(1, 'days').toISOString().split('T')[0];

    let codice_lavorazioni = lavorazioni.map(({codiceAppLavorazione}) => (codiceAppLavorazione))
    
    try {
      //plot
      let data_plot = await ApiService.getApi('scarti_produzione/plot', {start_date, end_date, granularity, cliente, articolo, codice_lavorazioni} )
      data_plot = data_plot.map(item => {
        return "wdate" in item ? { "quantita": item.quantita, 
                                    "scarti": item.scarti, 
                                    "efficienza_produzione": item.efficienza_produzione, 
                                    "scarti_perc": item.scarti_perc,
                                    "date": item.wdate}  : item
      });
      //console.log(data_plot)
      setDataPlot(data_plot)

      //menu
      let menu = await ApiService.getApi('scarti_produzione/menu', {start_date, end_date, cliente, articolo} ) 
      setMenu(menu)
      setLavorazioni(
        menu['lavorazioni'].filter(({ codiceAppLavorazione: id1 }) => !lavorazioniSelected.some(({ codiceAppLavorazione: id2 }) => id2 === id1)) 
      )

      //table
      let data_table = await ApiService.getApi('scarti_produzione/table', {start_date, end_date, cliente, articolo, codice_lavorazioni} )
      let data_table_merge = data_table.map(function(item) {
        return {
            cliente: item.cliente,
            articolo: item.articolo + ' - ' + item.descrizione_articolo,
            lavorazione: item.lavorazione + ' - ' + item.descrizione_lavorazione,
            scarti_perc: item.scarti_perc
        };
      });
      setDataTable(data_table_merge)

    } catch(err) {
      console.error(`Error when fetching data`, err);
      openAlert("error", "Errore imprevisto")
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchData(startDate, endDate, granularity, clienteSelected.GruppoCliente, articoloSelected, lavorazioniSelected);
  }, [startDate, endDate, granularity, clienteSelected, articoloSelected, lavorazioniSelected]);

  return (
    <>
      <LinearProgress style={{visibility: loading ? 'visible' : 'hidden'}}/>
      {userData && (
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h5" gutterBottom="true">
              Scarti di Produzione
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <p>
              Benvenuto, {userData['full_name']}
            </p>
          </Grid>
        </Grid>
      )}
      <hr style={{ marginTop: -5, marginBottom: 15 }} />
      {userData && (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
        >
          <Grid item xs={6} md={3}>
            {/* Autocomplete CLIENTI */}
            <Autocomplete
              id="combo-box-clienti"
              //style={{ width: 300 }}
              options={menu['cliente']}
              getOptionLabel={(option) => option.GruppoCliente}
              renderInput={(params) => <TextField {...params} label="Clienti" variant="outlined" />}
              value={clienteSelected}
              onChange={(e, newValue) => {
                if (newValue) {
                  setClienteSelected(newValue);
                } else {
                  setClienteSelected({});
                }
              }
              }
            />
          </Grid>
          <Grid item xs={6} md={3}>
            {/* Autocomplete ARTICOLI */}
            <Autocomplete
              id="combo-box-articoli"
              options={menu['articolo']}
              getOptionLabel={(option) => option.codiceArticolo + " - " + option.descrizioneArticolo}
              //style={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Articoli" variant="outlined" />}
              onChange={(event, newValue) => {
                if(newValue!=null){
                  setArticoloSelected(newValue.codiceArticolo);
                }else{
                  setArticoloSelected('');
                }
                
              }}
            />
          </Grid>
          <Grid item xs={2} md={2}>
              <FormControl variant='filled'>
                <InputLabel id='granularity-select-label'>Granularità</InputLabel>
                <Select
                  labelId='granularity-select-label'
                  id='granularity-select'
                  value={granularity}
                  onChange={handleGranularityChange}>
                  <MenuItem value={productionGranularities.DAILY}>Giornaliera</MenuItem>
                  <MenuItem value={productionGranularities.WEEKLY}>Settimanale</MenuItem>
                </Select>
              </FormControl>
          </Grid>
          <Grid item xs={5} md={2}>
            {/* DATETIME PICKER */}
            <DateTimePicker
              selectedDate={startDate}
              setSelectedDate={setStartDate}
              minDate={moment(new Date()).subtract({year: 1}).toDate()}
              maxDate={moment()}
              label="Data Inizio"
            />
          </Grid>
          <Grid item xs={5} md={2}>
            {/* DATETIME PICKER */}
            <DateTimePicker
              selectedDate={endDate}
              setSelectedDate={setEndDate}
              minDate={moment(new Date()).subtract({year: 1}).toDate()}
              maxDate={moment()}
              label="Data Fine"
            />
          </Grid>
          <Grid xs={9} md={9}>
          {/* MULTI AUTOCOMPLETE LAVORAZIONI */}
            <Autocomplete
              multiple
              id="multiple_lavorazioni_autocomplete"
              options={lavorazioni}
              getOptionLabel={(option) => option.codiceAppLavorazione}
              getOptionDisabled={() => { if (lavorazioniSelected.length >= MULTI_AUTOCOMPLETE_LIMIT) return true; }}
              value={lavorazioniSelected}
              onChange={(e, newDisplayedValue) => {
                setLavorazioniSelected(newDisplayedValue);
              }}
              renderOption={(option) => option.codiceAppLavorazione + " - " + option.descrizioneAppLavorazione}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Seleziona le lavorazioni"
                  variant="standard"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <LineChart id="line-chart"
                       data={dataPlot}
                       fields={['scarti_perc']}
                       mapper={prodKpiMappings["dict"]}
                       percent={true}
            />
          </Grid>
          <Grid item xs={12}>
            <GenericTable data={dataTable} columns={KPI_TEMPI_COLUMNS} />
          </Grid>
        </Grid>
      )}
    </>
  );
}
