import React, {useState, useEffect} from "react";
import {useParams} from "react-router";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import {LinearProgress} from "@material-ui/core";
import ApiService from "../../services/ApiService";
import AuthenticationService from "../../services/AuthenticationService";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    color: theme.palette.secondary.dark
  },
  subtitle: {
    textAlign: "center",
    marginTop: 10,
    marginBottom: 0
  },
  form: {
    maxWidth: "400px",
    marginTop: theme.spacing(1),
    padding: 20,
    marginLeft: "auto",
    marginRight: "auto"
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  submitRight: {
    position: "absolute",
    right: 25,
    margin: theme.spacing(3, 0, 2)
  }
}));

export default function Registration() {
  const classes = useStyles();
  const {token} = useParams();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [unauthorized, setUnauthorized] = useState(false);
  const [error, setError] = useState({value: false, msg: ""});
  const [confirmError, setConfirmError] = useState({value: false, msg: ""});
  const setOnError = (msg) => setError({value: true, msg});
  const setOffError = () => setError({value: false, msg: ""});
  const setOnConfirmError = (msg) => setConfirmError({value: true, msg});
  const setOffConfirmError = () => setConfirmError({value: false, msg: ""});

  useEffect(() => {
    setLoading(true)
    ApiService.publicPostApi('auth/registration/validate', {token: token})
      .then((data) => {
        setEmail(data.email)
      })
      .catch((err) => {
        console.error("Error:", err)
        setUnauthorized(true)
      })
      .finally(() => setLoading(false))
  }, [token])

  const submit = async () => {
    setLoading(true)
    ApiService.publicPostApi('auth/registration/submit', {email: email, password: password})
      .then((msg) => {
        console.log(msg)
        AuthenticationService.login(email, password)
          .then(() => {window.location = "/energia"})
          .catch((err) => {
            console.log(err)
            setUnauthorized(true)
          })
      })
      .catch((err) => {
        console.log("Error:", err)
        setUnauthorized(true)
      })
      .finally(() => setLoading(false))
  }

  const handlePassword = (e) => {
    const value = e.target.value
    if (value.length < 8) {
      setOnError("La password deve essere lunga almeno 8 caratteri")
    } else if (value.includes(' ')) {
      setOnError("La password non deve contenere spazi")
    } else {
      setOffError()
    }
    setPassword(e.target.value)
  }

  const handleConfirmPassword = (e) => {
    const value = e.target.value
    if (value !== password) {
      setOnConfirmError("Le due password non coincidono")
    } else {
      setOffConfirmError()
    }
    setConfirmPassword(e.target.value)
  }

  return (
    <React.Fragment>
      <LinearProgress style={{visibility: loading ? 'visible' : 'hidden'}}/>
      {unauthorized &&
      <div className={classes.paper}>
        <img
          className="mb-3"
          width="100px"
          src={process.env.PUBLIC_URL + "/logo.png"}
          alt="LOGO"
        />
        <Box m="2rem" textAlign="center">
          <Typography component="h1" variant="h6">
            Link di registrazione non valido
          </Typography>
        </Box>
      </div>
      }
      {!unauthorized && email &&
      <div className={classes.paper}>
        <img
          className="mb-1"
          width="100px"
          src={process.env.PUBLIC_URL + "/logo.png"}
          alt="LOGO"
        />
        <Typography variant="subtitle1" className={classes.title}>
          <b>Emmebi</b>
        </Typography>
        <Grid container className={classes.form}>
          <Grid item xs={12}>
            <p className={classes.subtitle}>
              <b>Registrazione utente</b>
            </p>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <p className={classes.subtitle}>
              <b>Scegli una password:</b>
            </p>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              value={password}
              onChange={handlePassword}
              error={error.value}
              helperText={error.msg}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12}>
            <p className={classes.subtitle}>
              <b>Conferma la password:</b>
            </p>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Conferma Password"
              type="password"
              id="password"
              value={confirmPassword}
              onChange={handleConfirmPassword}
              error={confirmError.value}
              helperText={confirmError.msg}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={4} style={{marginLeft: "auto", marginRight: "auto"}}>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              color="primary"
              className={classes.submit}
              disabled={!password || !confirmPassword || error.value || confirmError.value}
              onClick={submit}
            >
              Registrati
            </Button>
          </Grid>
        </Grid>
      </div>
      }
    </React.Fragment>
  );
}
