import _ from "lodash";
import React, { useEffect, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4lang_it_IT from "@amcharts/amcharts4/lang/it_IT";

am4core.useTheme(am4themes_animated);


function StackedBarChart({id, data, charTitle, fields, mapper, percent}) {
  const [chartRef, setChartRef] = useState(null);

  console.log("Rendering", data, charTitle);

  useEffect(() => {
    let chart = am4core.create(id, am4charts.XYChart);

    chart.data = data;
    chart.logo.disabled = true;
    chart.tapToActivate = false;

    chart.numberFormatter.numberFormat = "#,###.##";
    chart.numberFormatter.language.locale = am4lang_it_IT;

    // define chart granularity
    chart.dateFormatter.inputDateFormat = "yyyy-MM-dd HH:mm:ss";

    let title = chart.titles.create();
    title.text = charTitle;
    title.fontSize = 20;
    title.marginBottom = 20;

    // Create axes
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 50;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    if (percent) {
      valueAxis.max = 100;
      valueAxis.strictMinMax = true;
      valueAxis.calculateTotals = true;
      valueAxis.renderer.labels.template.adapter.add("text", (text) => {
        return `${text}%`;
      });
    } else {
      valueAxis.title.text = "Energia [[kWh]]";
    }

    function createSeries(field, name) {
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.name = name;
      series.dataFields.valueY = field;
      series.dataFields.dateX = "date";
      series.sequencedInterpolation = true;

      // Make it stacked
      series.stacked = true;

      series.columns.template.width = am4core.percent(60);
      if (percent) {
        series.dataFields.valueYShow = "totalPercent";
        series.columns.template.tooltipText = "[bold]{name}:[/][font-size:14px]{categoryX} {valueY.totalPercent.formatNumber('#.00')}%";
        series.tooltip.pointerOrientation = "vertical";
      } else {
        series.columns.template.tooltipText = "[bold]{name}:[/][font-size:14px]{categoryX} {valueY}";
      }

      // Add label
      let labelBullet = series.bullets.push(new am4charts.LabelBullet());
      if (percent) {
        labelBullet.label.text = "{valueY.totalPercent.formatNumber('#.00')}%";
      } else {
        labelBullet.label.text = "{valueY}";
      }
      labelBullet.locationY = 0.5;
      labelBullet.label.hideOversized = true;

      // Add chart cursor
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.behavior = "zoomX";

      return series;
    }

    for (let f of fields) {
      createSeries(f, mapper[f]);
    }

    // Legend
    chart.legend = new am4charts.Legend();

    setChartRef(chart);

    return () => {
      chart.dispose();
    };
  }, [data, id]);

  if (chartRef) {
    console.log("Created chart with id:", chartRef.baseId);
  }

  return (
    <div id={id} style={{ minWidth: "500px", minHeight: "400px" }} />
  );
}

export default React.memo(StackedBarChart, (props, nextProps) => {
  // Render component only when users changes
  return _.isEqual(props.data, nextProps.data) && _.isEqual(props.percent, nextProps.percent);
});
