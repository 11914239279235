import _ from "lodash";
import React, { useState, useEffect } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

// TODO: make it better!!
function GaugeChart({id, data, charTitle, minValue, maxValue, valueLabel}) {
  const [chartRef, setChartRef] = useState(null)

  useEffect(() => {
    let chart = am4core.create(id, am4charts.GaugeChart);
    // chart.language.locale["_thousandSeparator"] = " ";
    chart.innerRadius = am4core.percent(80);
    chart.logo.disabled = true
    chart.resizable = false;
    // chart.data = data;

    let title = chart.titles.create();
    title.text = charTitle;
    title.fontSize = 20;
    title.marginTop = 15;

    let axis = chart.xAxes.push(new am4charts.ValueAxis());
    axis.min = minValue;
    axis.max = maxValue;
    axis.strictMinMax = true;

    let range = axis.axisRanges.create();
    range.value = 0;
    range.endValue = data;
    range.axisFill.fillOpacity = 1;
    range.axisFill.fill = am4core.color("#67b7dc");
    range.axisFill.zIndex = -1;

    // Label 1

    let label = chart.radarContainer.createChild(am4core.Label);
    label.isMeasured = false;
    label.fontSize = 24;
    label.x = am4core.percent(20);
    label.paddingBottom = 30;
    label.horizontalCenter = "middle";
    label.verticalCenter = "bottom";
    label.text = data;

    // Label 2

    let label2 = chart.radarContainer.createChild(am4core.Label);
    label2.isMeasured = false;
    label2.fontSize = 18;
    label2.horizontalCenter = "middle";
    label2.verticalCenter = "bottom";
    label2.text = valueLabel;

    setChartRef(chart);

    return () => {
      chart.dispose();
    };
  }, [data, id]);

  if (chartRef) {
    console.log("Created chart with id:", chartRef.baseId);
  }

  return (
    <div id={id} style={{width: "100%", height: "380px", minWidth: "380px"}}/>
  );
}

export default React.memo(GaugeChart, (props, nextProps) => {
  // Render component only when users changes
  return _.isEqual(props.data, nextProps.data);

})