import moment from 'moment';
import React, {useState} from "react";
import { makeStyles } from "@material-ui/core/styles";

import { energy_kpi } from "../../utils/fakeData/Energia.json"
import { granularities, dates, siteMappings, energyKpiMappings } from "../../utils/constants";

// Services
import AuthenticationService from "../../services/AuthenticationService";

// UI
import Grid from "@material-ui/core/Grid";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from "@material-ui/core/Typography";
import FormControl from '@material-ui/core/FormControl';

// Components
import DonutChart from "../../components/Charts/DonutChart";
import HomeTable from "../../components/Tables/HomeTable";
import CategorySelector from "../../components/CategorySelector";
import StackedBarChart from "../../components/Charts/StackedBarChart";
import DateTimePicker from "../../components/DateTimePicker";

// Style
const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1
  },
  gridItem: {
    marginLeft: "auto",
    marginRight: "auto"
  }
}));

const ENERGY_COLUMNS_NAME = [
  { title: 'Data', field: 'date', type: 'date',
    render: rowData => <>{moment(rowData.date).format("DD/MM/YYYY hh:mm")} </> },
  { title: 'Somma di compressori', field: 'compressor', type: 'numeric',
    render: rowData => <>{(Math.round(rowData.compressor * 100) / 100).toFixed(2)} </> },
  { title: 'Somma di Produzione', field: 'production', type: 'numeric',
    render: rowData => <>{(Math.round(rowData.production * 100) / 100).toFixed(2)} </> }
];

const DonutChartSampleData = [
  {category: "Somma dei compressori", value: 64},
  {category: "Somma di produzione", value: 36}
];

// const initSiteId = "totale";
const initSiteId = "uffici";


export default function Energia() {
  const classes = useStyles();
  const userData = AuthenticationService.getUserData();
  // const [startDate, setStartDate] = useState(moment(new Date()).subtract({year: 1}).startOf('month').toDate());
  // const [endDate, setEndDate] = useState(moment(new Date()).subtract({month: 1}).endOf('month').toDate());
  const [startDate, setStartDate] = useState(moment(new Date(2021, 3, 15)).toDate());
  const [endDate, setEndDate] = useState(moment(new Date(2021, 3, 30)).toDate());
  const [granularity, setGranularity] = useState(granularities.HOUR);
  const [siteId, setSiteId] = useState(initSiteId);

  const handleGranularityChange = (value) => {
    setGranularity(value.target.value);
  };

  const handleCategoryChange = (value) => {
    setSiteId(value);
  };

  return (
    <>
      {userData && (
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h5" gutterBottom="true">
              Energia Elettrica
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <p>
              Benvenuto, {userData['full_name']}
            </p>
          </Grid>
        </Grid>
      )}
      <hr style={{ marginTop: -5, marginBottom: 15 }} />
      <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
      >
        <Grid item >
        </Grid>
        <Grid item >
          <CategorySelector
            categories={siteMappings['columns']}
            initCategory={siteId}
            color="primary"
            onCategoryChange={handleCategoryChange}
            size="small"
          />
        </Grid>
        <Grid item >
            <FormControl variant='filled'>
              <InputLabel id='granularity-select-label'>Granularità</InputLabel>
              <Select
                labelId='granularity-select-label'
                id='granularity-select'
                value={granularity}
                onChange={handleGranularityChange}>
                <MenuItem value={granularities.DAY}>Giornaliera</MenuItem>
                <MenuItem value={granularities.HOUR}>Oraria</MenuItem>
              </Select>
            </FormControl>
        </Grid>
        <Grid item >
          {/* DATETIME PICKER */}
          <DateTimePicker
            selectedDate={startDate}
            setSelectedDate={setStartDate}
            minDate={moment(new Date()).subtract({year: 1}).startOf('month').toDate()}
            maxDate={endDate}
            label="Data Inizio"
          />
        </Grid>
        <Grid item >
          {/* DATETIME PICKER */}
          <DateTimePicker
            selectedDate={endDate}
            setSelectedDate={setEndDate}
            minDate={startDate}
            maxDate={moment(new Date()).subtract({month: 1}).endOf('month').toDate()}
            label="Data Fine"
          />
        </Grid>
        <Grid item className={classes.gridItem}>
          <StackedBarChart
              id="line-chart1"
              data={energy_kpi}
              charTitle={"Consumo energetico"}
              fields={["compressor", "production"]}
              mapper={energyKpiMappings["dict"]}
              percent={false}
          />
        </Grid>
        <Grid item className={classes.gridItem}>
          <StackedBarChart
              id="line-chart2"
              data={energy_kpi}
              charTitle={"Consumo energetico percentuale"}
              fields={["compressor", "production"]}
              mapper={energyKpiMappings["dict"]}
              percent={true}
          />
        </Grid>
        <Grid item className={classes.gridItem}>
          <DonutChart
              id="donut-chart"
              charTitle={"Ripartizione Percentuale Complessiva"}
              data={DonutChartSampleData} />
        </Grid>
        <Grid item xs={12}>
          <HomeTable
              data={energy_kpi}
              columns={ENERGY_COLUMNS_NAME}
              titleLabel={siteMappings["dict"][siteId]}
          />
        </Grid>
      </Grid>

    </>
  );
}
