import React from "react";

// UI
import MaterialTable from 'material-table';
import _ from "lodash";

// Table Params
import {COLUMNS, OPTIONS, LOCALIZATION} from "./Params/Home"
import {tableIcons} from "./Params/Icons";

function HomeTable({data, titleLabel, columns}) {
  return (
    <>
      <MaterialTable
        columns={columns}
        data={data}
        icons={tableIcons}
        isLoading={data === undefined}
        options={OPTIONS}
        title={"Riepilogo " + titleLabel}
        localization={LOCALIZATION}
      />
    </>
  );
}

export default React.memo(HomeTable, (props, nextProps) => {
  // Render component only when users changes
  return _.isEqual(props.data, nextProps.data) && _.isEqual(props.titleLabel, nextProps.titleLabel);
})
