import React, {useState} from "react";

// Services
import AuthenticationService from "../../services/AuthenticationService";
import ApiService from "../../services/ApiService";

// Ui
import {Dialog, DialogContent, DialogTitle, LinearProgress, TextField, Paper, TableCell} from "@material-ui/core";
import {DialogContentText, Typography, Button, Grid} from "@material-ui/core";
import {TableContainer, Table, TableBody, TableRow} from "@material-ui/core";
import SimpleAlert from "../../components/Alert/SimpleAlert";
import {makeStyles} from "@material-ui/core/styles";
import theme from "../../utils/theme";

const empty_msg = {value: false, msg: ""};

const useStyles = makeStyles((theme) => ({
  alert: {
    marginTop: 50,
    width: "90%",
    marginLeft: "5%"
  },
  button: {
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  submitRight: {
    position: "absolute",
    right: 25,
    margin: theme.spacing(3, 0, 2)
  }
}));

export default function Profile() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const userData = AuthenticationService.getUserData();
  const [openPassword, setOpenPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState(empty_msg);
  const [newPassword, setNewPassword] = useState("");
  const [errorNewPassword, setErrorNewPassword] = useState(empty_msg);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorConfirmPassword, setErrorConfirmPassword] = useState(empty_msg);

  const [alert, setAlert] = useState({open: false, type: "", message: ""});
  const openAlert = (type, message) => {
    setAlert({open: true, type, message})
  }
  const toggleAlert = () => {
    setAlert({...alert, open: !alert.open})
  }

  const togglePassword = () => setOpenPassword(!openPassword)

  const handleChangePassword = async (e) => {
    e.preventDefault();
    if (newPassword.length < 6 || newPassword.includes(' ')) {
      setErrorNewPassword({value: true, msg: "Password non conferme"})
    } else if (confirmPassword !== newPassword) {
      setErrorNewPassword(empty_msg)
      setErrorConfirmPassword({value: true, msg: "Le password non coincidono"})
    } else {
      setLoading(true);
      setErrorNewPassword(empty_msg)
      setErrorConfirmPassword(empty_msg)
      const data = {
        'username': userData['username'],
        'password': password,
        'new_password': newPassword
      }
      ApiService.postApi('auth/password/reset', data)
        .then((res) => {
          console.log(res)
          togglePassword()
          openAlert("success", "Password modificata con successo")
        })
        .catch((err) => {
          console.log(err)
          openAlert("error", "Accesso negato")
        })
        .finally(() => {
          setLoading(false)
          setPassword("")
          setNewPassword("")
          setConfirmPassword("")
          setErrorPassword(empty_msg)
        })
    }
  }

  function readOnlyField(label, key) {
    return (
      <>
        <TableRow>
          <TableCell width="30%" height={50}><b>{label}</b></TableCell>
          <TableCell>{userData[key]}</TableCell>
        </TableRow>
      </>
    )
  }

  return (
    <>
      <LinearProgress style={{
        visibility: loading ? 'visible' : 'hidden'
      }}/>
      <Typography variant="h5">Profilo personale</Typography>
      <hr style={{marginBottom: 20}}/>
      <TableContainer component={Paper}>
        <Table aria-label="simple table" size="small">
          <TableBody>
            {readOnlyField("Tipo utente", 'role')}
            {readOnlyField("Nome", 'full_name')}
            {readOnlyField("Email", 'email')}
            {readOnlyField("Username", 'username')}
          </TableBody>
        </Table>
      </TableContainer>
      <Button variant="contained" color="primary" onClick={togglePassword} className={classes.button}>
        Modifica password
      </Button>
      <Dialog
        open={openPassword}
        onClose={togglePassword}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Modifica password"}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleChangePassword}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Password attuale"
              autoComplete="password"
              type="password"
              autoFocus
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={errorPassword.value}
              helperText={errorPassword.msg}
            />
            <DialogContentText style={{marginTop: theme.spacing(2)}}>
              Inserire una password lunga almeno 6 caratteri, senza spazi
            </DialogContentText>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Nuova password"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              error={errorNewPassword.value}
              helperText={errorNewPassword.msg}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Conferma password"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              error={errorConfirmPassword.value}
              helperText={errorConfirmPassword.msg}
            />
            <Grid container>
              <Grid item xs={5}>
                <Button onClick={togglePassword} color="primary" variant="contained" className={classes.submit}>
                  Esci
                </Button>
              </Grid>
              <Grid item xs={7}>
                <Button type="submit" variant="contained" className={classes.submitRight} disabled={loading}>
                  Invia
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      <SimpleAlert alert={alert} toggleAlert={toggleAlert}/>
    </>
  );

}
