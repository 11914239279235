import React from "react";

export const COLUMNS = [
  {
    title: "Giorni",
    field: "day",
    type: "string",
  },
  {
    title: "Ore",
    field: "hour",
    type: "string",
  },
  {
    title: "Numero",
    field: "value",
    type: "numeric",
    render: rowData => <>{Number(rowData['value']).toLocaleString('it-IT')}</>
  }
]

export const OPTIONS = {
  filtering: true,
  showTitle: true,
  toolbar: true,
  paging: false,
  sorting: true,
  headerStyle: {fontWeight: "bold", fontSize: 15, paddingTop: 5, paddingBottom: 5},
  filterCellStyle: {paddingTop: 8, maxWidth: 200},
  cellStyle: {paddingBottom: 5},
  rowStyle: x => {
    if (x.class === "Totale") {return {fontWeight: "bold"}}
  }
};

export const LOCALIZATION = {
  header: {
    actions: ""
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} di {count} righe",
    labelRowsSelect: "Righe",
  }
};
