// Api URL based on env
export const API_URL = "/api";

export const ROLES = [
  {value: "admin", label: "Admin"},
  {value: "user", label: "Utente base"}
];

export const granularities = {
    DAY: "day",
    HOUR: "hour",
};

export const productionGranularities = {
    DAILY: "daily",
    WEEKLY: "weekly",
    MONTHLY: "monthly",
};

// TODO: check the utility of this constant
export const dates = {
  MIN_DATE: new Date('2017-12-20 00:00:00'),
  HOURLY_DATE: new Date('2021-03-14 00:00:00'),
};

export const siteMappings = {
    'columns': [
        { title: 'Siti Complessivi', field: 'totale' },
        { title: 'Blocco Robot', field: 'robot' },
        { title: 'Blocco Uffici', field: 'uffici' },
        { title: 'Blocco Sbavatura', field: 'sbavatura' },
    ],
    'dict': {
        'totale': 'Siti Complessivi',
        'robot': 'Blocco Robot',
        'uffici': 'Blocco Uffici',
        'sbavatura': 'Blocco Sbavatura',
    }
};

export const centralMappings = {
    'columns': [
        { title: 'Complessivo Centrali', field: 'totale' },
        { title: 'Centrale Robot', field: 'robot' },
        { title: 'Centrale Uffici', field: 'uffici' },
        { title: 'Centrale Sbavatura', field: 'sbavatura' },
    ],
    'dict': {
        'totale': 'Complessivo Centrali',
        'robot': 'Centrale Robot',
        'uffici': 'Centrale Uffici',
        'sbavatura': 'Centrale Sbavatura',
    }
};

export const energyKpiMappings = {
    'columns': [
        { title: 'Somma di compressori', field: 'compressor' },
        { title: 'Somma di Produzione', field: 'production' },
        { title: 'Somma di Volume AC', field: 'ac_volume' },
        { title: 'Media di KPI', field: 'kpi' },
    ],
    'dict': {
        'compressor': 'Somma di compressori',
        'production': 'Somma di Produzione',
        'ac_volume': 'Somma di Volume AC',
        'kpi': 'Media di KPI',
    }
};

export const prodKpiMappings = {
    'columns': [
        { title: 'Quantità', field: 'quantita' },
        { title: "Quantità all'ora", field: 'quantita_ora' },
        { title: 'Quantità al giorno', field: 'quantita_giorno' },
        { title: 'Quantità lavorata', field: 'quantita_lavorata' },
    ],
    'dict': {
        'quantita': 'Quantità',
        'quantita_ora': "Quantità all'ora",
        'quantita_giorno': 'Quantità al giorno',
        'quantita_lavorata': 'Quantità lavorata',
        'efficienza_produzione': 'Efficienza produzione %',
        'scarti_perc': 'Efficienza produzione %'
    }
};

// TODO: remove value1 and value2
export const fakeKpiMappings = {
    'columns': [
        { title: 'Valore 1', field: 'value1' },
        { title: 'Valore 2', field: 'value2' },
    ],
    'dict': {
        'value1': 'Valore 1',
        'value2': 'Valore 2',
    }
};