import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import {Dialog, DialogContent, DialogContentText, DialogTitle, LinearProgress} from "@material-ui/core";
import AuthenticationService from "./../../services/AuthenticationService";
import axios from "axios";
import {API_URL} from "../../utils/constants";
import Box from "@material-ui/core/Box";


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    color: theme.palette.primary.dark
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(0),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  submitRight: {
    position: "absolute",
    right: 25,
    margin: theme.spacing(3, 0, 2)
  },
  powered: {
    textAlign: 'center',
    color: theme.palette.primary.dark,
    marginTop: 100
  }
}));

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [openForgot, setOpenForgot] = useState(false);
  const [error, setError] = useState({value: false, msg: "",});
  const [errorForgot, setErrorForgot] = useState({value: false, msg: ""});
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);

  const classes = useStyles();

  const setOnError = (msg) => setError({ value: true, msg });
  const setOffError = () => setError({ value: false, msg: "" });
  const setOnErrorForgot = (msg) => setErrorForgot({ value: true, msg });
  const setOffErrorForgot = () => setErrorForgot({ value: false, msg: "" });

  function toggleForgot() {
    setEmailSent(false)
    setOpenForgot(!openForgot);
  }

  async function recoverPassword(email) {
    return await axios
      .post(`${API_URL}/auth/password/forgot`, {email})
      .then((res) => {
        console.log(res)
        setOffErrorForgot();
        setEmailSent(true)
      })
      .catch((err) => {
        setOnErrorForgot("Email non registrata");
        console.log("Password recover failed.", err);
      });
  }

  const handleRecoverPassword = async (e) => {
    e.preventDefault();
    setLoading(true);

    await recoverPassword(email);
    setLoading(false);
  }

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    await AuthenticationService.login(username, password);

    if (AuthenticationService.isUserAuthenticated()) {
      setOffError();

      window.location = "/tempi";
    } else {
      setOnError("Email o Password non corretti!");
      setLoading(false);
      setPassword("");
    }
  };

  return (
    <React.Fragment>
      <LinearProgress style={{
        visibility: loading ? 'visible' : 'hidden'
      }}/>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Box m="1rem" textAlign="center">
            <img
              width="150px"
              src={process.env.PUBLIC_URL + "/logo.png"}
              alt="LOGO"
            />
            <Typography component="h1" variant="h5" className={classes.title}>
              <b>Emmebi</b>
            </Typography>
          </Box>
          <form className={classes.form} onSubmit={handleLogin}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              error={error.value}
              helperText={error.msg}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={error.value}
              helperText={error.msg}
            />
            <Grid container>
              <Grid item xs={8}>
                <Button
                  href=""
                  size="small"
                  color="primary"
                  className={classes.submit}
                  style={{marginTop: 26}}
                  onClick={toggleForgot}
                >
                  Password dimenticata?
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  className={classes.submit}
                >
                  Accedi
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
        <div className={classes.powered}>
          <p>Powered by</p>
          <a href='https://www.ammagamma.com' target="_blank" rel="noopener noreferrer" >
            <img
              style={{width: "5em", height: "auto", marginBottom: 'auto'}}
              src={process.env.PUBLIC_URL + "/ammagamma.png"}
              alt="LOGO"
            />
          </a>
        </div>
        <Dialog
          open={openForgot}
          onClose={toggleForgot}
        >
          <DialogTitle id="alert-dialog-title">{"Recupero password"}</DialogTitle>
          {!emailSent &&
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Inserisci l'indirizzo email associato alla tua utenza:
              </DialogContentText>
              <form className={classes.form} onSubmit={handleRecoverPassword}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Indirizzo email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={errorForgot.value}
                  helperText={errorForgot.msg}
                />
                <Grid container>
                  <Grid item xs={5}>
                    <Button onClick={toggleForgot} variant="contained" className={classes.submit}>
                      Esci
                    </Button>
                  </Grid>
                  <Grid item xs={7}>
                    <Button type="submit" color="primary" variant="contained" className={classes.submitRight} disabled={loading}>
                      Invia password
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </DialogContent>
          }
          {emailSent &&
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Nuova password inviata correttamente
              </DialogContentText>
              <Button onClick={toggleForgot} color="primary" variant="contained" className={classes.submit}>
                Esci
              </Button>
            </DialogContent>
          }
        </Dialog>
      </Container>
    </React.Fragment>
  );
}
