import _ from "lodash";
import React, { useState, useEffect } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

function DonutChart(props) {

  const [chartRef, setChartRef] = useState(null)

  useEffect(() => {
    let chart = am4core.create(props.id, am4charts.PieChart);
    chart.language.locale["_thousandSeparator"] = " ";
    chart.logo.disabled = true
    chart.data = props.data;

    let title = chart.titles.create();
    title.text = props.charTitle;
    title.fontSize = 20;
    title.marginTop = 15;

    // Set inner radius
    chart.innerRadius = am4core.percent(50);

    // Add and configure Series
    let pieSeries = chart.series.push(new am4charts.PieSeries());
    // noinspection JSUndefinedPropertyAssignment
    pieSeries.dataFields.value = "value";
    // noinspection JSUndefinedPropertyAssignment
    pieSeries.dataFields.category = "category";
    pieSeries.slices.template.stroke = am4core.color("#fff");
    pieSeries.slices.template.strokeWidth = 1;
    pieSeries.slices.template.strokeOpacity = 1;

    pieSeries.ticks.template.disabled = true;
    pieSeries.labels.template.disabled = true;

    // This creates initial animation
    pieSeries.hiddenState.properties.opacity = 1;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;

    let label = pieSeries.createChild(am4core.Label);
    label.text = "{values.value.sum}";
    label.horizontalCenter = "middle";
    label.verticalCenter = "middle";
    label.fontSize = 30;

    // Legend
    chart.legend = new am4charts.Legend();
    // chart.legend.labels.template.text = "{category}"
    // chart.legend.valueLabels.template.text = "{value.value}";
    // chart.legend.position = "bottom";
    // chart.legend.scrollable = true;
    // chart.legend.fontSize = 13;
    // chart.legend.itemContainers.template.paddingTop = 5;
    // chart.legend.itemContainers.template.paddingBottom = 5;
    // let markerTemplate = chart.legend.markers.template;
    // markerTemplate.width = 13;
    // markerTemplate.height = 13;

    setChartRef(chart)
  }, [props.data]);

  if (chartRef) {
    console.log("Created chart with id:", chartRef.baseId);
  }

  return (
    <div id={props.id} style={{width: "100%", height: "380px", minWidth: "380px"}}/>
  );
}


export default React.memo(DonutChart, (props, nextProps) => {
  // Render component only when users changes
  return _.isEqual(props.data, nextProps.data);

})