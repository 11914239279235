import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { ROLES } from "../../../utils/constants";

export const COLUMNS = [
  {
    title: "Username",
    field: "username",
  },
  {
    title: "E-mail",
    field: "email",
  },
  {
    title: "Nome",
    field: "full_name",
    render: ({ full_name }) => <b>{full_name}</b>,
  },
  {
    title: "Scopes",
    field: "scopes",
    editComponent: ({ scopes }) => <b>{scopes}</b>,
  },
];

export const OPTIONS = {
  filtering: true,
  pageSize: 10,
  searchFieldAlignment: "left",
  searchFieldStyle: {
    marginTop: "2px",
  },
  showTitle: false,
  toolbar: false,
};

export const LOCALIZATION = {
  header: {
    actions: "",
  },
  body: {
    editRow: {
      deleteText: "Eliminare l'utenza?",
    },
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} di {count} utenti",
    labelRowsSelect: "Utenti",
  },
};
