import React from "react";
import {Link} from "react-router-dom";
import AuthenticationService from "../../../services/AuthenticationService";
import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded";

import {makeStyles} from "@material-ui/core/styles";
import {
  Avatar,
  Divider,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText
} from "@material-ui/core";

import menu from "./menu";

import {useLocation} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  listItem: {
    paddingBottom: 4,
    paddingTop: 4
  },
  listText: {
    color: theme.palette.secondary.dark
  },
  listIcon: {
    color: theme.palette.primary.main
  },
  tabTitle: {
    color: theme.palette.secondary.dark
  },
  listImg: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "80px"
  },
  primary: {
    backgroundColor: theme.palette.primary.dark,
  },
}));

export function List({disabled, to, onClick, icon, text}) {
  const classes = useStyles();
  const actualPath = useLocation().pathname;

  const selected = actualPath === to;

  return (
    <ListItem
      button
      disabled={disabled}
      component={Link}
      to={to}
      onClick={onClick}
      selected={selected}
      className={classes.listItem}
    >
      <ListItemIcon className={classes.listIcon}>{icon}</ListItemIcon>
      <ListItemText className={classes.listText} primary={text}/>
    </ListItem>
  );
}

export default function Menu({onClick}) {
  let userRole = null;
  if (AuthenticationService.isUserAuthenticated()) {
    userRole = AuthenticationService.getUserData().role;
  }

  return (
    <>
      {menu.map(({disabled, to, icon, text, divider, role}) =>
        divider ? (
          <Divider key={Math.random()} style={{marginTop: 10, marginBottom: 10}}/>
        ) : (
          ((role && role.includes(userRole)) || !role) &&
          <List disabled={disabled} key={to} to={to} onClick={onClick} icon={icon} text={text}/>
        )
      )}
    </>
  );
}

export function ListHeader() {
  const classes = useStyles();

  return (
    <>
      <ListItem>
        <ListItemText
          primary={
            <>
              <div style={{display: "flex", verticalAlign: "middle", marginBottom: 10}}>
                <img
                  src={process.env.PUBLIC_URL + "/logo.png"}
                  className={classes.listImg}
                  alt="LOGO"
                />
              </div>
            </>
          }
        />
      </ListItem>
    </>
  );
}

export function ListUser({
  userName, toggleDrawer, logoutHandler
}
)
{
  const classes = useStyles();
  let firstLetter = "";

  if (!userName) {
    userName = "Utente";
  }

  firstLetter = userName
    .toString()
    .trim()
    .split(" ")
    .reduce((letters, string) => {
      letters += string[0].toString().toUpperCase();

      return letters;
    }, "");

  return (
    <>
      <ListItem component={Link} to={"/profile"} onClick={toggleDrawer} className={classes.listItem}>
        <ListItemAvatar>
          <Avatar>{firstLetter}</Avatar>
        </ListItemAvatar>
        <ListItemText className={classes.listText}>
          <b>{userName}</b>
        </ListItemText>
        <ListItemSecondaryAction>
          <IconButton edge="end" onClick={logoutHandler}>
            <ExitToAppRoundedIcon/>
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </>
  );
}
