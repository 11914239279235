import "./index.css";
import App from "./App";
import ReactDOM from "react-dom";

import { Provider } from "unstated";
import { BrowserRouter } from "react-router-dom";

import { ThemeProvider } from '@material-ui/core/styles';
import theme from "./utils/theme";

import { CssBaseline } from "@material-ui/core";
import React from "react";

ReactDOM.render(
  <Provider>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
