import React from "react";

import GroupAddIcon from "@material-ui/icons/GroupAdd";
import MultilineChartOutlinedIcon from '@material-ui/icons/MultilineChartOutlined';
import AccessibilityNewRoundedIcon from '@material-ui/icons/AccessibilityNewRounded';
import SettingsApplicationsRoundedIcon from '@material-ui/icons/SettingsApplicationsRounded';

const menu = [
  {
    divider: true,
  },
//  {
//    disabled: true,
//    text: <b> Energia </b>,
//    icon: <MultilineChartOutlinedIcon />,
//  },
//  {
//    to: "/energia",
//    disabled: false,
//    text: "Energia Elettrica",
//  },
//  {
//    to: "/compressori",
//    disabled: false,
//    text: "Aria Compressa",
//  },
//  {
//    divider: true,
//  },
  {
    disabled: true,
    text: <b> Produzione </b>,
    icon: <SettingsApplicationsRoundedIcon />,
  },
  {
    to: "/tempi",
    disabled: false,
    text: "Tempi",
  },
  {
    to: "/scarti_produzione",
    disabled: false,
    text: "Scarti",
  },
  {
    divider: true,
  },
  {
    disabled: true,
    text: <b> Risorse </b>,
    icon: <AccessibilityNewRoundedIcon />,
  },
  {
    to: "/tempi_operatori",
    disabled: false,
    text: "Tempi operatori",
  },
  {
    divider: true,
  },
  {
    to: "/users",
    text: "Gestione Utenze",
    icon: <GroupAddIcon />,
    role: ["admin"],
  },
];

export default menu;
