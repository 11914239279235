import axios from "axios";
import { API_URL } from "../../utils/constants";
import AuthenticationService from "../AuthenticationService";
import _ from "lodash";

const obj2urlParams = (o) => {
  if (!o){
    return '';
  }
  return `?${
    _.entries(o)
      .reduce((params, [key, value]) => {
        if (_.isArray(value)) {
          params = params.concat(value.map(value => `${key}=${value}`));
        } else {
          params.push(`${key}=${value}`);
        }
        return params;
      }, [])
      .join("&")
  }`
}

export default class ApiService {
  static getHeaders() {
    const userData = AuthenticationService.getUserData();
    return { Authorization: "Bearer " + userData.access_token };
  }

  static async getApi(typeObject, args) {
    const baseUrl = `${API_URL}/${typeObject}/`;
    const url = args === undefined ? baseUrl : baseUrl + `${obj2urlParams(args)}`;
    return await axios
      .get(url, { headers: this.getHeaders() })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static async postApi(typeObject, data) {
    return await axios
      .post(
        `${API_URL}/${typeObject}/`,
        { data },
        { headers: this.getHeaders() }
      )
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static async postApiJson(typeObject, data) {
    const jsonData = JSON.stringify(data);
    let requestHeaders = this.getHeaders();
    requestHeaders["Content-Type"] = "application/json";
    return await axios
      .post(`${API_URL}/${typeObject}/`, jsonData, { headers: requestHeaders })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static async publicPostApi(typeObject, data) {
    return await axios
      .post(`${API_URL}/${typeObject}/`, { data })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  static async deleteApi(typeObject) {
    return await axios
      .delete(`${API_URL}/${typeObject}/`, { headers: this.getHeaders() })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }
}
