import './App.css';
import React from "react";
import {BrowserRouter as Router, Route, Redirect} from "react-router-dom";
import {PrivateRoute} from "./components/PrivateRoute";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import AppNavigation from "./components/AppNavigation";

import Login from "./containers/Login";
import Energia from "./containers/Energia";
import Compressori from "./containers/Compressori";
import TempiProduzione from "./containers/TempiProduzione";
import ScartiProduzione from "./containers/ScartiProduzione";
import TempiOperatori from "./containers/TempiOperatori";
import Profile from "./containers/Profile";
import Registration from "./containers/Registration";
import Users from "./containers/Users";


const useStyles = makeStyles(() => ({
  main: {
    marginTop: "80px",
    maxWidth: "1500px"
  },
}));

function App() {
  const classes = useStyles();

  return (
    <>
      <Router>
        <PrivateRoute
          exact
          path="/"
          component={() => <Redirect to={{pathname: "/tempi"}}/>}
        />
        <Route path="/login" component={Login}/>
        <Route exact path="/registration/:token" component={Registration} />

        <Route
          path={
            [
              "/profile",
              "/users",
              "/energia",
              "/compressori",
              "/tempi",
              "/scarti_produzione",
              "/tempi_operatori",
            ]
          }
          render={({location, history}) => (
            <React.Fragment>
              <AppNavigation history={history}/>
              <Container maxWidth="lg" className={classes.main}>
                <PrivateRoute exact path="/profile" component={Profile}/>
                <PrivateRoute exact path="/users" component={Users}/>
                <PrivateRoute exact path="/energia" component={Energia}/>
                <PrivateRoute exact path="/compressori" component={Compressori}/>
                <PrivateRoute exact path="/tempi" component={TempiProduzione}/>
                <PrivateRoute exact path="/scarti_produzione" component={ScartiProduzione}/>
                <PrivateRoute exact path="/tempi_operatori" component={TempiOperatori}/>
              </Container>
            </React.Fragment>
          )}
        />
      </Router>
    </>
  );
}

export default App;
