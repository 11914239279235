import moment from 'moment';
import { React, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { energy_kpi } from "../../utils/fakeData/Energia.json"
import { centralMappings, granularities, dates, energyKpiMappings } from "../../utils/constants";

// Services
import AuthenticationService from "../../services/AuthenticationService";

// UI
import Grid from "@material-ui/core/Grid";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from "@material-ui/core/Typography";
import FormControl from '@material-ui/core/FormControl';

// Components
import BarChart from "../../components/Charts/BarChart";
import HomeTable from "../../components/Tables/HomeTable";
import GaugeChart from "../../components/Charts/GaugeChart";
import DateTimePicker from "../../components/DateTimePicker";
import CategorySelector from "../../components/CategorySelector";
import MultiAxisLineChart from "../../components/Charts/MultiAxisLineChart";

// Style
const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1
  },
  gridItem: {
    marginLeft: "auto",
    marginRight: "auto"
  }
}));

const AC_COLUMNS_NAME = [
  { title: 'Data', field: 'date', type: 'date',
    render: rowData => <>{moment(rowData.date).format("DD/MM/YYYY hh:mm")} </> },
  { title: 'Somma di compressori', field: 'compressor', type: 'numeric',
    render: rowData => <>{(Math.round(rowData.compressor * 100) / 100).toFixed(2)} </> },
  { title: 'Somma di Volume AC', field: 'ac_volume', type: 'numeric',
    render: rowData => <>{(Math.round(rowData.ac_volume * 100) / 100).toFixed(2)} </> },
  { title: 'Media di KPI', field: 'kpi', type: 'numeric',
    render: rowData => <>{(Math.round(rowData.kpi * 100) / 100).toFixed(2)} </> },
]

// const initCentralId = "totale";
const initCentralId = "uffici";


export default function Compressori() {
  const classes = useStyles();
  const userData = AuthenticationService.getUserData();
  const [startDate, setStartDate] = useState(moment(new Date(2021, 3, 15)).toDate());
  const [endDate, setEndDate] = useState(moment(new Date(2021, 3, 30)).toDate());
  const [granularity, setGranularity] = useState(granularities.HOUR);
  const [centralId, setCentralId] = useState(initCentralId);

  const handleGranularityChange = (value) => {
      setGranularity(value.target.value);
  };

  const handleCategoryChange = (value) => {
    setCentralId(value);
  };

  return (
    <>
      {userData && (
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h5" gutterBottom="true">
              Centrali Aria Compressa
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <p>
              Benvenuto, {userData['full_name']}
            </p>
          </Grid>
        </Grid>
      )}
      <hr style={{ marginTop: -5, marginBottom: 15 }} />
      <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
      >
        <Grid item >
        </Grid>
        <Grid item >
          <CategorySelector
            categories={centralMappings['columns']}
            initCategory={centralId}
            color="primary"
            onCategoryChange={handleCategoryChange}
            size="small"
          />
        </Grid>
        <Grid item >
            <FormControl variant='filled'>
              <InputLabel id='granularity-select-label'>Granularità</InputLabel>
              <Select
                labelId='granularity-select-label'
                id='granularity-select'
                value={granularity}
                onChange={handleGranularityChange}>
                <MenuItem value={granularities.DAY}>Giornaliera</MenuItem>
                <MenuItem value={granularities.HOUR}>Oraria</MenuItem>
              </Select>
            </FormControl>
        </Grid>
        <Grid item >
          {/* DATETIME PICKER */}
          <DateTimePicker
            selectedDate={startDate}
            setSelectedDate={setStartDate}
            minDate={moment(new Date()).subtract({year: 1}).startOf('month').toDate()}
            maxDate={endDate}
            label="Data Inizio"
          />
        </Grid>
        <Grid item >
          {/* DATETIME PICKER */}
          <DateTimePicker
            selectedDate={endDate}
            setSelectedDate={setEndDate}
            minDate={startDate}
            maxDate={moment(new Date()).subtract({month: 1}).endOf('month').toDate()}
            label="Data Fine"
          />
        </Grid>
        <Grid item className={classes.gridItem}>
          <BarChart
              id="bar-chart"
              data={energy_kpi}
              charTitle="Energia / Aria Compressa"
              fields={["kpi"]}
              mapper={energyKpiMappings["dict"]}/>
        </Grid>
        <Grid item className={classes.gridItem}>
          <MultiAxisLineChart id="line-chart"
                     data={energy_kpi}
                     charTitle={"Energia e Aria Compressa"}
                     fields={['ac_volume', 'compressor']}
                     mapper={energyKpiMappings["dict"]}/>
        </Grid>
        <Grid item className={classes.gridItem}>
          <GaugeChart
            id={"gauge-chart"}
            data={0.106}
            charTitle={"Volume / Aria Compressa media"}
            minValue={0}
            maxValue={0.238}
            valueLabel={"Valore medio"}
            displayPercent={false}
          />
        </Grid>
        <Grid item xs={12}>
          <HomeTable
              data={energy_kpi}
              columns={AC_COLUMNS_NAME}
              titleLabel={centralMappings["dict"][centralId]}
          />
        </Grid>
      </Grid>

    </>
  );
}
